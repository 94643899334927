// extracted by mini-css-extract-plugin
export var twoColumnSidebar = "textpages-module--twoColumnSidebar--2LVQI";
export var twoColumnSidebarLeft = "textpages-module--twoColumnSidebarLeft--3Nku7";
export var twoColumnSidebarLeftSelection = "textpages-module--twoColumnSidebarLeftSelection--2HFVD";
export var twoColumnSidebarLeftTitle = "textpages-module--twoColumnSidebarLeftTitle--1abXm";
export var twoColumnSidebarLeftElementSelected = "textpages-module--twoColumnSidebarLeftElementSelected--17K1t";
export var twoColumnSidebarLeftElement = "textpages-module--twoColumnSidebarLeftElement--1nTDi";
export var twoColumnContentRight = "textpages-module--twoColumnContentRight--3fsPA";
export var twoColumnContentRightTitle = "textpages-module--twoColumnContentRightTitle--15Y88";
export var twoColumnContentRightSubtitle = "textpages-module--twoColumnContentRightSubtitle--QNCPq";
export var twoColumnContentRightSubsubtitle = "textpages-module--twoColumnContentRightSubsubtitle--3rgR1";