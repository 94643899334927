import * as React from 'react'
import Layout from '../components/Layout'
import LinkBar from '../components/LinkBar'

import {Helmet} from "react-helmet"
import {StaticImage} from "gatsby-plugin-image"
import {Link} from 'gatsby'

//Modular stylesheet
import{twoColumnSidebar, twoColumnContentRight, twoColumnSidebarLeft, twoColumnSidebarLeftSelection, twoColumnSidebarLeftTitle, twoColumnContentRightTitle, twoColumnSidebarLeftElement,
    twoColumnSidebarLeftElementSelected, twoColumnContentRightSubtitle, twoColumnContentRightSubsubtitle} from '../components/styles/textpages.module.css'

//Global stylesheet
import "../components/styles/global.css"


const traveladvisories = () => {
    return(
        <Layout pageTitle={"traveladvisories"}>
            <Helmet>
                <meta charSet="UTF-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>

                <title>Travel advisories | Bangbiangyang Tourism</title>

                <meta name="author" content="aqq.xyz"/>
                <meta name="description" content="View current travel advisories and warnings at the official site for Tourism Bangbiangyang"/>

                <meta property="og:title" content="Travel advisories | Bangbiangyang Tourism"/>
                <meta property="og:description" content="View current travel advisories and warnings at the official site for Tourism Bangbiangyang"/>
                <meta property="og:url" content="bangbiangyang.aqq.xyz/traveladvisories"/>
                <meta property="og:site_name" content="bangbiangyang.aqq.xyz"/>

                <meta name="robots" content="noindex"/>

                <link rel="icon" href="../static/favicon.ico" />
            </Helmet>

            <LinkBar>
                <Link to="/">Home</Link> > <Link to="travel advisories">Travel advisories</Link>
            </LinkBar>

            <div className="midContentWrapper">
                <div className={twoColumnSidebar}>
                    <div className={twoColumnSidebarLeft}>
                        <p className={twoColumnSidebarLeftTitle}>
                            Travel advisories and information
                        </p>
                        <div className={twoColumnSidebarLeftSelection}>
                            <Link to="/traveladvisories" className={twoColumnSidebarLeftElementSelected}>
                                Current advisories
                            </Link>
                            <Link to="/traveladvisories/past" className={twoColumnSidebarLeftElement}>
                                Past advisories
                            </Link>
                        </div>

                    </div>
                    <div className={twoColumnContentRight}>
                        <h3 className={twoColumnContentRightTitle}>Current travel advisories</h3>
                        <h4 className={twoColumnContentRightSubtitle}>Updated travel restrictions from 31/08/2021</h4>
                        <p>
                            From midnight on August 31, additional requirements will be placed on all foreign travellers entering Bangbiangyang.
                        </p>

                        <h5 className={twoColumnContentRightSubsubtitle}>If you are a Bangbiangyang citizen or resident returning from overseas</h5>
                        <p>
                            Limited random entry screenings may be performed on incoming Bangbiangyang citizens. You are advised to carry your National Identity Card at all times
                            and comply with any instructions from Internal Security staff.
                        </p>

                        <h5 className={twoColumnContentRightSubsubtitle}>If you are a partner or dependent child of a Bangbiangyang citizen or resident</h5>
                        <p>
                            You will be required to provide and present documentation of your relationship with a Bangbiangyang citizen or resident
                            at your entry screening.
                        </p>
                        <p>
                            If you have a spiritual, elemental, or otherwise Immaterial Affinity Index greater than 4.95, you will be asked to register your personal
                            details with the Ministry of Internal Security. You may be requested to undergo a brief aptitude test upon arrival.
                        </p>
                        <p>
                            Children under the age of 16 are exempt from aptitude testing.
                        </p>

                        <h5 className={twoColumnContentRightSubsubtitle}>If you are a foreign citizen entering Bangbiangyang</h5>
                        <p>
                            Compulsory comprehensive entry screenings will be required upon arrival, regardless of age.
                        </p>
                        <p>
                            The Ministry of Internal Security now conducts comprehensive screenings at its new purpose-built facilities. These can be a short distance away from your original arrival location.
                            Return transport to these facilities will be provided in groups; however, as all screenings are conducted on a randomised individual basis, we are unable to accommodate
                            any group placement requests. Families and other dependent travellers should discuss and agree on a reunion plan prior to arrival.
                        </p>
                        <p>
                            If you have a spiritual, elemental, or otherwise Immaterial Affinity Index greater than 4.95, you will be provided with a dimensionally unified location
                            tracking device. It is required by law that you wear this device on your person at all times until your departure from Bangbiangyang.
                        </p>
                        <p>
                            Personal belongings will be processed before being released to you.
                        </p>


                        <h4 className={twoColumnContentRightSubtitle}>Temporary border suspension for specific foreign nationals</h4>
                        <p>
                            Travel into Bangbiangyang is continuously monitored for potential physical and immaterial threats. The <Link to="/Intentional404" class="standardLink">
                            Ministry of Internal Security</Link> makes regular updates to our border controls and may temporarily suspend entry for residents of high-risk countries.
                        </p>
                        <p>
                            The following countries, regions, and/or territories are currently under a border suspension. Incoming travellers will be denied entry into Bangbiangyang and
                            returned to their originating location at their own expense.
                        </p>
                        <ul class="standardList">
                            <li>
                                China,
                            </li>
                            <li>
                                North Korea,
                            </li>
                            <li>
                                Russia, and
                            </li>
                            <li>
                                Thailand
                            </li>
                        </ul>
                        <p>
                            Travellers that may be eligible for special entry from these countries include Bangbiangyang citizens or permanent residents, their partners and/or children, and
                            those with notable diplomatic, humanitarian, or spiritual affiliations.
                        </p>
                        <p>
                            All travellers wishing to enter under these special entry conditions are required to apply to the Ministry of Internal Security. It is important to remember that,
                            unlike some other nations, Bangbiangyang citizenship or residency <strong>does not guarantee</strong> that your entry request will be approved. Such decisions
                            are always made at discretion and affected travellers are advised to seek alternative solutions.
                        </p>
                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default traveladvisories